
import { computed, defineComponent, onMounted, reactive } from "vue";
import Avatar from "@/components/console/avatars/Avatar.vue";
import ButtonText from "@/components/console/buttons/ButtonText.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "InputComponent",
  components: { ButtonText, Avatar },
  props: {
    comment: {
      type: Object,
      required: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["saveComment", "hideCommentInput"],
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      avatarImg: computed(() => {
        return store.getters["auth/user"].avatar;
      }),
      comment: "",
      saveDisabled: computed(() => {
        return state.comment.trim().length === 0;
      }),
      isEdit: !!props.comment,
    });

    onMounted(() => {
      if (state.isEdit) {
        state.comment = props.comment.content;
      }
    });

    const saveComment = () => {
      if (state.saveDisabled) {
        return;
      }
      emit("saveComment", state.comment);
    };

    const initComment = (e = null) => {
      if (e) {
        e.target.value = "";
      }
      state.comment = "";
    };

    const actions = {
      updateComment: (e) => {
        state.comment = e.target.value;
      },
      onEnterInitComment: (e) => {
        initComment(e);
      },
      onEnterSaveComment: () => {
        saveComment();
      },
      onClickInitComment: () => {
        initComment();
        if (props.isEdit) {
          emit("hideCommentInput");
        }
      },
      onClickSaveComment: () => {
        saveComment();
        initComment();
      },
    };

    return { state, actions };
  },
});
