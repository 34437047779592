import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f5718a7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "profile" }
const _hoisted_4 = { class: "txt" }
const _hoisted_5 = { class: "sub-text-s3" }
const _hoisted_6 = { class: "sub-text-s3 text-gray-second" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_more_verti_icon = _resolveComponent("more-verti-icon")!
  const _component_button_text = _resolveComponent("button-text")!
  const _component_button_dropdown_item = _resolveComponent("button-dropdown-item")!
  const _component_button_dropdown = _resolveComponent("button-dropdown")!
  const _component_avatar = _resolveComponent("avatar")!
  const _component_input_comment = _resolveComponent("input-comment")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["comment", `comment-${_ctx.index}`])
  }, [
    (!_ctx.state.isEditMode)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_button_dropdown, {
            distance: "8",
            class: "more-verti__btn",
            container: `.comment-${_ctx.index}`
          }, {
            button: _withCtx(() => [
              _createVNode(_component_button_text, {
                padding: "0",
                "is-icon": true
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_more_verti_icon)
                ]),
                _: 1
              })
            ]),
            dropdownList: _withCtx(() => [
              _createElementVNode("ul", null, [
                (!_ctx.state.isMyself)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
                      _createVNode(_component_button_dropdown_item, {
                        text: "신고하기",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.actions.reportComment()))
                      })
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.state.isMyself)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
                      _createVNode(_component_button_dropdown_item, {
                        text: "수정하기",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.actions.toggleEditMode()))
                      }),
                      _createVNode(_component_button_dropdown_item, {
                        text: "삭제하기",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.actions.deleteComment()))
                      })
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["container"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_avatar, {
              size: "32",
              "avatar-img": _ctx.comment.user?.avatar
            }, null, 8, ["avatar-img"]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.state.writerName), 1),
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.comment.createdAtHumans), 1)
            ])
          ]),
          _createElementVNode("p", {
            class: "b-text-2",
            innerHTML: _ctx.comment.contentHtml
          }, null, 8, _hoisted_7)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.state.isEditMode)
      ? (_openBlock(), _createBlock(_component_input_comment, {
          key: 1,
          comment: _ctx.comment,
          "is-edit": true,
          onSaveComment: _cache[3] || (_cache[3] = (value) => _ctx.actions.editComment(value)),
          onHideCommentInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.actions.toggleEditMode()))
        }, null, 8, ["comment"]))
      : _createCommentVNode("", true)
  ], 2))
}