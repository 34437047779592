import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40cfd324"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-comment" }
const _hoisted_2 = { class: "input-area" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "action-btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avatar = _resolveComponent("avatar")!
  const _component_button_text = _resolveComponent("button-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_avatar, {
      "avatar-img": _ctx.state.avatarImg,
      size: 32
    }, null, 8, ["avatar-img"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("textarea", {
        rows: "1",
        class: "b-text-2",
        placeholder: "댓글을 입력해주세요",
        value: _ctx.state.comment,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.actions.updateComment($event))),
        onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.actions.onEnterSaveComment()), ["exact","prevent"]), ["enter"])),
        onKeyup: _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.actions.onEnterInitComment($event)), ["exact","prevent"]), ["enter"]))
      }, null, 40, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_button_text, {
          text: "취소",
          "text-size": "s3",
          color: "#818287",
          padding: "0",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.actions.onClickInitComment()))
        }),
        _createVNode(_component_button_text, {
          text: "등록",
          "text-size": "s3",
          padding: "0",
          color: _ctx.state.saveDisabled ? '#D0D5DE' : '#8E1EFF',
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.actions.onClickSaveComment()))
        }, null, 8, ["color"])
      ])
    ])
  ]))
}