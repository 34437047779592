
import {
  computed,
  defineComponent,
  onMounted,
  onUpdated,
  PropType,
  reactive,
} from "vue";
import Avatar from "@/components/console/avatars/Avatar.vue";
import ButtonDropdown from "@/pages/console/ContentShow/ButtonDropdown/ButtonDropdown.vue";
import ButtonText from "@/components/console/buttons/ButtonText.vue";
import MoreVertiIcon from "@/components/console/icons/MoreVertiIcon.vue";
import ButtonDropdownItem from "@/components/console/buttons/ButtonDropdownItem.vue";
import InputComment from "@/components/console/comments/InputComment.vue";
import { useStore } from "vuex";
import { Comment } from "@/types/comments";
import helper from "@/helper";

export default defineComponent({
  name: "Comment",
  components: {
    ButtonDropdownItem,
    MoreVertiIcon,
    ButtonText,
    ButtonDropdown,
    Avatar,
    InputComment,
  },
  props: {
    comment: {
      type: Object as PropType<Comment>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: ["showDeleteCommentModal", "reportComment", "editComment"],
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      writerName: computed(() => {
        return helper.displayUserName(props.comment);
      }),
      isMyself: false,
      isEditMode: false,
    });

    onMounted(() => {
      state.isMyself = state.user.resourceId === props.comment.user?.resourceId;
    });

    onUpdated(() => {
      if (state.isEditMode) {
        console.log("update", props.comment.resourceId);
        console.log(props.comment);
      }
    });

    const actions = {
      reportComment: () => {
        emit("reportComment");
      },
      deleteComment: () => {
        emit("showDeleteCommentModal");
      },
      toggleEditMode: () => {
        state.isEditMode = !state.isEditMode;
      },
      editComment: (value) => {
        store
          .dispatch("lectures/putLectureArticleCommunityComment", {
            commentResourceId: props.comment.resourceId,
            content: value,
          })
          .then(() => {
            emit("editComment");
            state.isEditMode = false;
          });
      },
    };
    return { state, actions };
  },
});
